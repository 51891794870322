import React from "react"

// Comps
import HeaderCircle from "../misc/HeaderCircle"

// Layout
import { Row, Cell } from "../layout/Layout"

// Styles
import headerStyles from "./Header.module.styl"

const Header = props => (
    <header className={headerStyles.Header}>
        <Row>
            <Cell start="0" end="12">
                <div className={headerStyles.Header__inner}>
                    <div className={headerStyles.Header__left}>
                        <HeaderCircle />
                    </div>
                    <div className={headerStyles.Header__right}>
                        <h1 className="heading-1">Clément Bardon</h1>
                        <p className="teasing-2">
                            Front-end developer&nbsp;&nbsp;–&nbsp;&nbsp;Unlikely Technology
                        </p>
                    </div>
                </div>
                <hr className={headerStyles.Header__separator} />
            </Cell>
        </Row>
    </header>
)

export default Header
