import React from "react"
import { Helmet } from "react-helmet"

// MISC
import meta from "../../images/meta.gif"

const Metas = () => (
    <Helmet>
        <title>Clément Bardon — Front-end developer</title>

        <link rel="canonical" href="https://www.clement.ninja" />
        <meta
            name="description"
            content="I'm a front-end developer from Paris, graduated from Gobelins, l'école de l'image."
        />
        <meta name="image" content={meta} />

        <meta itemprop="name" content="Clément Bardon — Front-end developer" />
        <meta
            itemprop="description"
            content="I'm a front-end developer from Paris, graduated from Gobelins, l'école de l'image."
        />
        <meta itemprop="image" content={meta} />

        <meta name="twitter:card" content="summary_large_image" />
        <meta
            name="twitter:title"
            content="Clément Bardon — Front-end developer"
        />
        <meta
            name="twitter:description"
            content="I'm a front-end developer from Paris, graduated from Gobelins, l'école de l'image."
        />
        <meta name="twitter:site" content="@clmntclmnt" />
        <meta name="twitter:image" content={meta} />

        <meta
            property="og:title"
            content="Clément Bardon — Front-end developer"
        />
        <meta
            property="og:description"
            content="I'm a front-end developer from Paris, graduated from Gobelins, l'école de l'image."
        />
        <meta property="og:url" content="http://www.clement.ninja" />
        <meta
            property="og:site_name"
            content="Clément Bardon — Front-end developer"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={meta} />
    </Helmet>
)

export default Metas
